import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import map from '../assets/img/Map.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faSearch, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { whatsappSummery } from '../api/Api';
import { HOST_URL } from '../api/Api';
class Reports extends Component {
    constructor() {
        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;

        var today = new Date(),
            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + ("0" + today.getDate()).slice(-2);
        super();

        this.state = {
            user_id: userId,
            token: userToken,
            TodayDate: date,
            dateFrom: date,
            dateTo: date,
            SelecteddateFrom: '',
            SelecteddateTo: '',
            todayRequestReports: '',
            latencyReport: '',
            smsReports: '',
            voiceReports: '',
            emailReports: '',
            countryList: '',
            whatsappSummeryData: [],
        }
    }

    componentDidMount() {
        this.todayRequests(this.state.TodayDate, this.state.TodayDate);
        this.latencyReport(this.state.TodayDate, this.state.TodayDate);
        this.smsReportSummery(this.state.TodayDate, this.state.TodayDate);
        this.voiceReportSummery(this.state.TodayDate, this.state.TodayDate);
        this.emailReportSummery(this.state.TodayDate, this.state.TodayDate);
        this.setCountry(this.state.TodayDate, this.state.TodayDate);

        whatsappSummery(this.state.dateFrom, this.state.dateTo).then(res => {
            this.setState({ whatsappSummeryData: [res.data.message] })
        })

    }

    fiterDatewiseData() {
        this.todayRequests(this.state.dateFrom, this.state.dateTo);
        this.latencyReport(this.state.dateFrom, this.state.dateTo);
        this.smsReportSummery(this.state.dateFrom, this.state.dateTo);
        this.voiceReportSummery(this.state.dateFrom, this.state.dateTo);
        this.emailReportSummery(this.state.dateFrom, this.state.dateTo);
        this.setCountry(this.state.dateFrom, this.state.dateTo);
        whatsappSummery(this.state.dateFrom, this.state.dateTo).then(res => {
            this.setState({ whatsappSummeryData: [res.data.message] })
        })

    }

    todayRequests(dateFrom, dateTo) {
        fetch(`${HOST_URL}/total_requests.php?user_id=${this.state.user_id}&token=${this.state.token}&date_from=${dateFrom}&date_to=${dateTo}`).then((response) => {
            response.json().then((result) => {
                // console.log(result)
                if (result.success === true) {
                    this.setState({ todayRequestReports: result.message });
                } else {
                    this.setState({ smsSuccessMessage: true, errorMessage: result.message, bgColor: "alert alert-danger alert-dismissible" })
                }
            })
        })
    }

    latencyReport(dateFrom, dateTo) {
        fetch(`${HOST_URL}/latency_report.php?user_id=${this.state.user_id}&token=${this.state.token}&date_from=${dateFrom}&date_to=${dateTo}`).then((response) => {
            response.json().then((result) => {
                //  console.log(result)
                if (result.success === true) {
                    this.setState({ latencyReport: result.message });
                } else {
                    this.setState({ smsSuccessMessage: true, errorMessage: result.message, bgColor: "alert alert-danger alert-dismissible" })
                }
            })
        })
    }

    smsReportSummery(dateFrom, dateTo) {
        fetch(`${HOST_URL}/sms_report.php?user_id=${this.state.user_id}&method=summary&token=${this.state.token}&date_from=${dateFrom}&date_to=${dateTo}`).then((response) => {
            response.json().then((result) => {
                // console.log(result)
                if (result.success === true) {
                    this.setState({ smsReports: result.message });
                } else {
                    this.setState({ smsSuccessMessage: true, errorMessage: result.message, bgColor: "alert alert-danger alert-dismissible" })
                }
            })
        })
    }

    voiceReportSummery(dateFrom, dateTo) {
        fetch(`${HOST_URL}/voice_report.php?user_id=${this.state.user_id}&method=summary&token=${this.state.token}&date_from=${dateFrom}&date_to=${dateTo}`).then((response) => {
            response.json().then((result) => {
                if (result.success === true) {
                    // console.log(result)
                    this.setState({ voiceReports: result.message, voiceReportsKeys: Object.keys(result.message) });
                } else {
                    this.setState({ voicesuccessMessage: true, errorMessage: result.message, bgColor: "alert alert-danger alert-dismissible" })
                }
            })
        })
    }

    emailReportSummery(dateFrom, dateTo) {
        fetch(`${HOST_URL}/email_report.php?user_id=${this.state.user_id}&method=summary&token=${this.state.token}&date_from=${dateFrom}&date_to=${dateTo}`).then((response) => {
            response.json().then((result) => {
                // console.log(result)
                if (result.success === true) {
                    this.setState({ emailReports: result.message, emailReportsKeys: Object.keys(result.message) });
                } else {
                    this.setState({ emailsuccessMessage: true, errorMessage: result.message, bgColor: "alert alert-danger alert-dismissible" })
                }
            })
        })
    }

    setCountry(dateFrom, dateTo) {
        fetch(`${HOST_URL}/top_countries.php?user_id=${this.state.user_id}&method=summary&token=${this.state.token}&date_from=${dateFrom}&date_to=${dateTo}`).then((response) => {
            response.json().then((result) => {
                //console.log(result)
                if (result.success === true) {
                    this.setState({ countryList: result.message });
                } else {
                    this.setState({ emailsuccessMessage: true, errorMessage: result.message, bgColor: "alert alert-danger alert-dismissible" })
                }
            })
        })
    }

    dateFrom = (date) => {
        let dated = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + ("0" + date.getDate()).slice(-2);
        this.setState({
            dateFrom: dated,
            SelecteddateFrom: date
        });
    };

    dateTo = (date) => {
        let dated = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + ("0" + date.getDate()).slice(-2);
        this.setState({
            dateTo: dated,
            SelecteddateTo: date
        });
    };

    render() {
console.log(this.state.whatsappSummeryData);
        return (
            <div className="wrapper">
                <DashboardLeftMenu />
                <div className="main">
                    <DashboardHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="mb-2 row">
                                <div className="col-auto col-md-3 d-none d-sm-block">
                                    <h3><strong>Analytics </strong> Report </h3>
                                </div>
                            </div>
                            <div className="">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row mb-4">

                                            {/* <div className="input-group col-md-4 mb-2">
                                          <div className="input-group-text">
                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                          </div>
                                        <DatePicker className="form-control btn-block"
                                            dateFormat="yyyy/MM/dd" 
                                            value={this.state.dateFrom}
                                            onChange={this.dateFrom}
                                            selected={this.state.SelecteddateFrom}
                                            placeholderText='Date From'
                                            /> 

                                    </div> */}
                                            <div className="col-12 col-md-6 col-lg-4">
                                                <div className="input-group mb-2 mr-sm-2">
                                                    <div className="input-group-text d-flex align-items-center justify-content-center" style={{ width: '40px' }}>
                                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                                    </div>
                                                    <DatePicker
                                                        className="form-control"
                                                        dateFormat="yyyy/MM/dd"
                                                        value={this.state.dateFrom}
                                                        onChange={this.dateFrom}
                                                        selected={this.state.SelecteddateFrom}
                                                        placeholderText="From Date"
                                                    />
                                                </div>
                                            </div>

                                            {/* <div className="input-group col-md-4 mb-2">
                                          <div className="input-group-text">
                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                          </div>
                                          <DatePicker className="form-control btn-block"
                                            dateFormat="yyyy/MM/dd"
                                            value={this.state.dateTo}
                                            onChange={this.dateTo}
                                            selected={this.state.SelecteddateTo}
                                            placeholderText='Date To'
                                            
                                            /> 
                                    </div> */}

                                            <div className="col-12 col-md-6 col-lg-4">
                                                <div className="input-group mb-2 mr-sm-2 d-flex">
                                                    <div className="input-group-text d-flex align-items-center justify-content-center" style={{ width: '40px' }}>
                                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                                    </div>
                                                    <DatePicker
                                                        className="form-control"
                                                        dateFormat="yyyy/MM/dd"
                                                        value={this.state.dateTo}
                                                        onChange={this.dateTo}
                                                        selected={this.state.SelecteddateTo}
                                                        placeholderText="Date To"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <button type="button" onClick={() => this.fiterDatewiseData()} className="btn-primary btn-block btn-sm ml-auto"> <FontAwesomeIcon icon={faSearch} /> Filter </button>
                                            </div>


                                        </div>
                                        <hr className="mt-0" />

                                        <div className="row">

                                            <div className="col-md-4">
                                                <h4 className="summary-title">Requests</h4>
                                                <div style={{ height: 156 + 'px' }} className="card">
                                                    <div className="card-body">

                                                        <div className="d-flex summary-data text-center">
                                                            <div className="mb-1">
                                                                <span className="text-muted"> total  </span> <br />
                                                                <span className="textvalue">  <FontAwesomeIcon icon={faArrowUp} color="green" size='1x' /> {
                                                                    parseInt(this.state.todayRequestReports.request) +
                                                                    parseInt(this.state.todayRequestReports.failed)
                                                                }

                                                                </span> </div>
                                                            <div className="mb-1">
                                                                <span className="text-muted"> Success  </span> <br />
                                                                <span className="textvalue"> <FontAwesomeIcon icon={faArrowUp} color="green" size='1x' /> {this.state.todayRequestReports.request} </span> </div>
                                                            <div className="mb-1">
                                                                <span className="text-muted"> Failed  </span>
                                                                <br /> <span className="textvalue"> <FontAwesomeIcon icon={faArrowDown} color="red" size='1x' /> {this.state.todayRequestReports.failed} </span> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-8">
                                                <h4 className="summary-title">Latency Report</h4>
                                                <div className="card">
                                                    <div className="table-responsive">
                                                        <table className="table analytics-report table-striped table-sm">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ borderTopLeftRadius: 10 + 'px' }} className=" d-md-table-cell">Channel</th>


                                                                    <th className=" d-xl-table-cell">0_15_sec </th>

                                                                    <th className=" d-md-table-cell">16_30_sec</th>

                                                                    <th className=" d-md-table-cell">31_45_sec</th>

                                                                    <th className=" d-md-table-cell">46_60_sec</th>

                                                                    <th style={{ borderTopRightRadius: 10 + 'px' }} className=" d-md-table-cell"> {`>`} 60_sec</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody className="text-center">
                                                                <tr>
                                                                    <td><strong>SMS</strong></td>

                                                                    <td>{this.state.latencyReport.SMSSec15}</td>
                                                                    <td>{this.state.latencyReport.SMSSec30}</td>
                                                                    <td>{this.state.latencyReport.SMSSec45}</td>
                                                                    <td>{this.state.latencyReport.SMSSec60}</td>
                                                                    <td>{this.state.latencyReport.SMSMore60}</td>

                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Voice</strong></td>

                                                                    <td>{this.state.latencyReport.VoiceSec15}</td>
                                                                    <td>{this.state.latencyReport.VoiceSec30}</td>
                                                                    <td>{this.state.latencyReport.VoiceSec45}</td>
                                                                    <td>{this.state.latencyReport.VoiceSec60}</td>
                                                                    <td>{this.state.latencyReport.VoiceMore60}</td>

                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Email</strong></td>

                                                                    <td>{this.state.latencyReport.EmailSec15}</td>
                                                                    <td>{this.state.latencyReport.EmailSec30}</td>
                                                                    <td>{this.state.latencyReport.EmailSec45}</td>
                                                                    <td>{this.state.latencyReport.EmailSec60}</td>
                                                                    <td>{this.state.latencyReport.EmailMore60}</td>

                                                                </tr>



                                                            </tbody>
                                                        </table>


                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h4 className="summary-title">SMS</h4>


                                                        <div className="d-flex summary-data text-center">


                                                            <div className="mb-1">
                                                                <span className="text-muted"> total  </span> <br />
                                                                <span className="textvalue"> <FontAwesomeIcon icon={faArrowUp} color="green" size='1x' /> {this.state.smsReports.total} </span> </div>
                                                            <div className="mb-1">
                                                                <span className="text-muted"> Success  </span> <br />
                                                                <span className="textvalue"> <FontAwesomeIcon icon={faArrowUp} color="green" size='1x' /> {this.state.smsReports.Delivered} </span> </div>
                                                            <div className="mb-1">
                                                                <span className="text-muted"> Failed  </span>
                                                                <br /> <span className="textvalue"> <FontAwesomeIcon icon={faArrowDown} color="red" size='1x' /> {this.state.smsReports.Failed} </span> </div>
                                                        </div>
                                                        <Link className="viewdetals" to={'/dashboard/sms-detail-reports/' + 'sms/' + this.state.dateFrom + '/' + this.state.dateTo}>View Details</Link>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h4 className="summary-title">Voice</h4>

                                                        <div className="d-flex summary-data text-center">
                                                            <div className="mb-1">
                                                                <span className="text-muted"> total  </span> <br />
                                                                <span className="textvalue"> <FontAwesomeIcon icon={faArrowUp} color="green" size='1x' /> {this.state.voiceReports.total} </span> </div>
                                                            <div className="mb-1">
                                                                <span className="text-muted"> Success  </span> <br />
                                                                <span className="textvalue"> <FontAwesomeIcon icon={faArrowUp} color="green" size='1x' /> {this.state.voiceReports.Success} </span> </div>
                                                            <div className="mb-1">
                                                                <span className="text-muted"> Failed  </span>
                                                                <br /> <span className="textvalue"> <FontAwesomeIcon icon={faArrowDown} color="red" size='1x' /> {this.state.voiceReports.Failed} </span> </div>
                                                        </div>
                                                        <Link className="viewdetals" to={'/dashboard/voice-detail-reports/' + 'voice/' + this.state.dateFrom + '/' + this.state.dateTo}>View Details</Link>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h4 className="summary-title">Email</h4>

                                                        <div className="d-flex summary-data text-center">
                                                            <div className="mb-1">
                                                                <span className="text-muted"> total  </span> <br />
                                                                <span className="textvalue"> <FontAwesomeIcon icon={faArrowUp} color="green" size='1x' /> {this.state.emailReports.total} </span> </div>

                                                            <div className="mb-1">
                                                                <span className="text-muted"> Sent  </span> <br />
                                                                <span className="textvalue"> <FontAwesomeIcon icon={faArrowUp} color="green" size='1x' /> {this.state.emailReports.Sent} </span> </div>

                                                            <div className="mb-1">
                                                                <span className="text-muted"> Delivered  </span> <br />
                                                                <span className="textvalue"> <FontAwesomeIcon icon={faArrowUp} color="green" size='1x' /> {this.state.emailReports.Delivered} </span> </div>
                                                            <div className="mb-1">
                                                                <span className="text-muted"> Opened  </span>
                                                                <br /> <span className="textvalue"> <FontAwesomeIcon icon={faArrowDown} color="red" size='1x' /> {this.state.emailReports.Opened} </span> </div>

                                                            <div className="mb-1">
                                                                <span className="text-muted"> Bounce  </span>
                                                                <br /> <span className="textvalue"> <FontAwesomeIcon icon={faArrowDown} color="red" size='1x' /> {this.state.emailReports.Bounce} </span> </div>


                                                        </div>
                                                        <Link className="viewdetals" to={'/dashboard/email-detail-reports/' + 'email/' + this.state.dateFrom + '/' + this.state.dateTo}>View Details</Link>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h4 className="summary-title">WhatsApp API Summary </h4>

                                                        <div className="d-flex summary-data text-center">

                                                            {this.state.whatsappSummeryData.map((item, i) =>
                                                                Object.keys(item).slice().reverse().map((key) =>

                                                                    <div className="mb-1">
                                                                        <span className="text-muted"> {key} </span> <br />
                                                                        <span className="textvalue"> <FontAwesomeIcon icon={faArrowUp} color="green" size='1x' /> {item[key]} </span>
                                                                    </div>
                                                                )

                                                            )}
                                                        </div>
                                                        <Link className="viewdetals" to={'/dashboard/whatsapp-detail-reports/' + this.state.dateFrom + '/' + this.state.dateTo}>View Details</Link>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-md-6 border-right">

                                                                <h4 className="summary-title">Top Country</h4>
                                                                <img className="img-fluid" src={map} />


                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="">
                                                                    <div className="allcountry">
                                                                        {this.state.countryList ?
                                                                            <div className="">

                                                                                {
                                                                                    this.state.countryList.map((item, i) =>
                                                                                        <div className="countryALl">
                                                                                            <span> <span className="circle1 bg-success"></span> {item.country} <strong>({item.code})</strong></span>
                                                                                            <span>{item.count}</span>
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                            </div>
                                                                            :
                                                                            null
                                                                        }



                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <DashboardFooter />
                </div>
            </div>
        );
    }
}
export default Reports;