import React, { Component } from "react";
import AdminHeader from "../Header/AdminHeader";
import AdminFooter from "../Footer/AdminFooter";
import AdminLeftMenu from "../Navbar/AdminLeftMenu";
import { userloginDetails } from "../Utils/AdminAuth";
import { HOST_URL } from "../Dashboard/api/Api";
import Spining from "../UI/Spining/Spining";

class AddNewClient extends Component {
  constructor() {
    const lStorage = userloginDetails();
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;

    super();

    this.state = {
      user_id: userId,
      token: userToken,
      userDtl:lStorage,
      email: "",
      mobile: "",
      fullname: "",
      password: "",
      userType: "",
      successMessage: false,
      errorMessage: "",
      bgColor: "",
      loading: false,
    };
  }

  AddnewClients(e) {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    fetch(
      `${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&name=${this.state.fullname}&email=${this.state.email}&mobile=${this.state.mobile}&password=${this.state.password}&method=adduser&token=${this.state.token}&user_type=${this.state.userType}`
    ).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-success alert-dismissible",
            errorMessage: result.message,
          });
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
    this.setState({
      loading: false,
    });
  }

  render() {
    //console.log(this.state)
    return (
      <div className="wrapper">
        <AdminLeftMenu />
        <div className="main">
          <AdminHeader />
          {this.state.loading && <Spining />}
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    <strong>Add New </strong> Client{" "}
                  </h3>
                </div>

                {this.state.successMessage ? (
                  <div className={this.state.bgColor} role="alert">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="">{this.state.errorMessage}</div>
                  </div>
                ) : null}
              </div>
              <div className="row">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div className=" flex-fill">
                          <form onSubmit={(e) => this.AddnewClients(e)}>
                            <div className="row">
                              <div className="mb-3 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="inputFirstName"
                                >
                                  Full Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  required
                                  onChange={(event) =>
                                    this.setState({
                                      fullname: event.target.value,
                                    })
                                  }
                                  className="form-control"
                                  placeholder="Enter Full Name"
                                />
                              </div>
                              <div className="mb-3 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="inputFirstName"
                                >
                                  Select Type{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <select
                                  required
                                  className="form-control"
                                  aria-label="Default select example"
                                  onChange={(event) =>
                                    this.setState({
                                      userType: event.target.value,
                                    })
                                  }
                                >
                                  <option value="" selected>
                                    Select Type
                                  </option>
                                  <option value="client">Client</option>
                                  {this.state.user_id === 1 ? (
                                    <>
                                      <option value="emp">Employee</option>
                                      <option value="affiliate">
                                        Affiliate
                                      </option>
                                    </>
                                  ) : null}
                                  {(this.state.userDtl.user.isAdmin === "emp" || this.state.user_id === 1)&&<option value="reseller">Reseller</option>}
                                </select>
                              </div>
                              <div className="mb-3 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="inputEmail4"
                                >
                                  Email <span className="text-danger">*</span>
                                </label>
                                <input
                                  required
                                  type="email"
                                  onChange={(event) =>
                                    this.setState({
                                      email: event.target.value,
                                    })
                                  }
                                  className="form-control"
                                  placeholder="Email"
                                />
                              </div>
                              <div className="mb-3 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="inputFirstName"
                                >
                                  Enter Mobile Number{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  required
                                  type="text"
                                  onChange={(event) =>
                                    this.setState({
                                      mobile: event.target.value,
                                    })
                                  }
                                  className="form-control"
                                  placeholder="Enter 10 Digit Mobile Number"
                                />
                              </div>
                              <div className="mb-3 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="inputFirstName"
                                >
                                  Enter Password{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  required
                                  type="text"
                                  onChange={(event) =>
                                    this.setState({
                                      password: event.target.value,
                                    })
                                  }
                                  className="form-control"
                                  placeholder="Enter Password"
                                />
                              </div>
                            </div>

                            <div className="float-right">
                              <input
                                type="submit"
                                value="Add Client "
                                className="btn btn-sm  btn-primary"
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <AdminFooter />
        </div>
      </div>
    );
  }
}

export default AddNewClient;
