import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ReactTelInput from "react-telephone-input";
import flags from "../../Dashboard/assets/img/flags.png";
import "react-telephone-input/css/default.css";
import SmsAPiSampleCode from "../../Dashboard/sms/SmsSampleCode";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import Title from "../../UI/Text/Title";
import Alert from "../../UI/Alert/Alert";
import { HOST_URL } from "../api/Api";
class SendSms extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    super();
    this.state = {
      UserDetails: [],
      tempList: "",
      templateData: [],
      message: "",
      senderid: "",
      pe_id: "",
      template_id: "",
      user_id: userId,
      token: userToken,
      authkey: "",
      templateID: "",
      template_name: "",
      countryCode: "91",
      mobile: "",
      sid: "",
      DefaultTemplateSelected: "",
      charachtercount: "",
      creditcount: "",
      hideSection: true,
      successMessage: false,
      errorMessage: "",
      bgColor: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.getTemplateDetails = this.getTemplateDetails.bind(this);
  }

  componentDidMount() {
    this.getUserDetails();
    this.GetListOfTemplate();
  }

  getUserDetails() {
    fetch(
      `${HOST_URL}/profile.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log(result)
        if (result.success === true) {
          this.setState({
            UserDetails: result.data[0],
            authkey: result.data[0].authkey,
            mobile: result.data[0].mobile,
          });
        }
      });
    });
  }

  GetListOfTemplate() {
    fetch(
      `${HOST_URL}/sms_template.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log(result)
        if (result.success === true) {
          this.setState({
            tempList: result,
          });
        } else {
        }
      });
    });
  }

  DefaultTemplateSelected(e, hideSection) {
    fetch(
      `${HOST_URL}/sms_template.php?user_id=${this.state.user_id}&id=${e}&method=retrieveid&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log(result)
        if (result.success === true) {
          this.setState({
            hideSection: hideSection,
            templateData: result.data[0],
            message: result.data[0].content,
            senderid: result.data[0].senderid,
            pe_id: result.data[0].entity_id,
            template_id: result.data[0].template_id,
            sid: result.data[0].id,
          });
        }
      });
    });
  }

  getTemplateDetails(e) {
    fetch(
      `${HOST_URL}/sms_template.php?user_id=${this.state.user_id}&id=${e.target.value}&method=retrieveid&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        console.log(result);

        if (result.success === true) {
          if (result.data[0].entity_id === "1001487500000015900") {
            this.setState({
              hideSection: false,
              templateData: result.data[0],
              message: result.data[0].content,
              DefaultTemplateSelected: result.data[0].name,
              senderid: result.data[0].senderid,
              pe_id: result.data[0].entity_id,
              template_id: result.data[0].template_id,
              sid: result.data[0].id,
            });
            this.countCharacter(result.data[0].content);
          } else {
            this.setState({
              hideSection: true,
              templateData: result.data[0],
              message: result.data[0].content,
              DefaultTemplateSelected: result.data[0].name,
              senderid: result.data[0].senderid,
              pe_id: result.data[0].entity_id,
              template_id: result.data[0].template_id,
              sid: result.data[0].id,
            });
            this.countCharacter(result.data[0].content);
          }
        }
      });
    });
  }

  SendMessage() {
    fetch(
      `https://api.authkey.io/request?authkey=${this.state.authkey}&mobile=${this.state.mobile}&country_code=${this.state.countryCode}&sid=${this.state.sid}`
    ).then((response) => {
      response.json().then((result) => {
        document.querySelector("body").scrollTo(0, 0);
        this.setState({ successMessage: true });
        this.setState({ bgColor: "alert alert-success alert-dismissible" });
        this.setState({ errorMessage: JSON.stringify(result) });
      });
    });
  }

  handleInputChange(telNumber) {
    this.setState({ countryCode: telNumber.substring(1) });
    // console.log('input changed. number: ', telNumber);
  }

  countCharacter(character) {
    var len = character.replace(/{|}|\[|\]|~|\^|\||\\/g, "  ");
    this.setState({ charachtercount: len.length });
    var totalCharacter = len.length;

    if (totalCharacter <= 160) {
      this.setState({ creditcount: Math.ceil(totalCharacter / 160) });
    } else {
      this.setState({ creditcount: Math.ceil(totalCharacter / 153) });
    }
  }

  render() {
    return (
      <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <Title> Get Started With Authkey SMS </Title>
            <div className="">
              {this.state.successMessage && (
                <Alert type={this.state.bgColor}>
                  {this.state.errorMessage}
                </Alert>
              )}

              <div className="card">
                <div className="card-body">
                  <h3>Send a Test Message</h3>

                  <div className="form-group  row">
                    <label
                      for="colFormLabel"
                      className="col-sm-2 col-form-label"
                    >
                      Send Message to{" "}
                    </label>
                    <div className="col-sm-4">
                      <div className="">
                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div className="">
                              <ReactTelInput
                                defaultCountry="in"
                                flagsImagePath={flags}
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          <input
                            type="text"
                            defaultValue={this.state.UserDetails.mobile}
                            onChange={(event) =>
                              this.setState({ mobile: event.target.value })
                            }
                            className="form-control"
                            id="inlineFormInputGroup"
                            placeholder="+912384930480"
                          />
                        </div>
                      </div>
                    </div>

                    <label
                      for="colFormLabel"
                      className="col-sm-2 text-right col-form-label"
                    >
                      Select Template{" "}
                    </label>
                    <div className="col-sm-4 text-right">
                      <div className="input-group drop-down-icons mb-2 ">
                        {this.state.tempList ? (
                          <select
                            value={this.state.DefaultTemplateSelected}
                            onChange={this.getTemplateDetails}
                            className="form-control"
                          >
                            <option>Select Template</option>
                            {this.state.tempList.data.map((item, i) => (
                              <option key={i} value={item.id}>
                                {item.template_name}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <div id="addTemplateBox">
                            <label className="form-label">
                              {" "}
                              <FontAwesomeIcon icon={faPlus} color="" />{" "}
                              <Link to="/dashboard/dlt-template-list">
                                {" "}
                                Add Template{" "}
                              </Link>{" "}
                            </label>
                          </div>
                        )}
                      </div>
                      <small></small>
                    </div>
                  </div>

                  {this.state.hideSection ? (
                    <>
                      <div className="form-group row">
                        <label
                          for="colFormLabel"
                          className="col-sm-2 col-form-label"
                        >
                          Entity ID{" "}
                        </label>
                        <div className="col-sm-4">
                          <div className="">
                            <div className="input-group mb-2">
                              <input
                                type="text"
                                defaultValue={this.state.templateData.entity_id}
                                disabled
                                className="form-control"
                                id="inlineFormInputGroup"
                              />
                            </div>
                          </div>
                        </div>
                        <label
                          for="colFormLabel"
                          className="col-sm-2 text-right col-form-label"
                        >
                          Template ID{" "}
                        </label>
                        <div className="col-sm-4">
                          <div className="">
                            <div className="input-group mb-2">
                              <input
                                type="text"
                                disabled
                                defaultValue={
                                  this.state.templateData.template_id
                                }
                                className="form-control"
                                id="inlineFormInputGroup"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          for="colFormLabel"
                          className="col-sm-2 col-form-label"
                        >
                          Sender ID{" "}
                        </label>
                        <div className="col-sm-10">
                          <div className="">
                            <div className="input-group mb-2">
                              <input
                                type="text"
                                disabled
                                defaultValue={this.state.templateData.senderid}
                                className="form-control"
                                id="inlineFormInputGroup"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div className="form-group row">
                    <label
                      for="colFormLabel"
                      className="col-sm-2 col-form-label"
                    >
                      {" "}
                      Send Message Content{" "}
                    </label>
                    <div className="col-sm-10">
                      <div className="">
                        <div className="input-group mb-2">
                          <textarea
                            rows={5}
                            disabled
                            onChange={(event) =>
                              this.setState({ message: event.target.value })
                            }
                            defaultValue={this.state.templateData.content}
                            className="form-control"
                          ></textarea>
                        </div>
                        <div className="character-count">
                          <p>
                            {" "}
                            <strong> {this.state.charachtercount} </strong>{" "}
                            Character Used{" "}
                          </p>{" "}
                          <p className="ml-2 mr-2"> / </p>{" "}
                          <p>
                            {" "}
                            <strong>
                              {" "}
                              {this.state.creditcount}{" "}
                            </strong> Credits{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={() => this.SendMessage()}
                    className="btn mt-3 btn-sm text-right button-cus"
                  >
                    {" "}
                    Test Message
                  </button>

                  <h3 className="mt-5 mb-3">Sample Code</h3>
                  <SmsAPiSampleCode />
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    );
  }
}

export default SendSms;
