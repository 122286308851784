import React, { useEffect, useState } from "react";
import ChatLeftMenu from "./ChatLeftMenu";
import DashboardLayout from "../../../UI/Layout/DashboardLayout";
import Axios from "axios";
import { NODE_HOST_URL } from "../../api/Api";
import Select, { components } from "react-select";
import Alert from "../../../UI/Alert/Alert";
import Spining from "../../../UI/Spining/Spining";

const Settings = () => {
  const lStorage = JSON.parse(localStorage.getItem("login"));
  const user_id = lStorage.user.userId;
  const token = lStorage.token;
  const brand_number = localStorage.getItem("wp-cmpy-brand_number");

  const [selectedHs, setSelectedHs] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [agentList, setAgentList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [tagStatus, setTagStatus] = useState(false);
  const [fallbackStrategy, setFallbackStartegy] = useState("");
  const [error, setError] = useState({
    successMessage: false,
    bgColor: "alert-danger",
    errorMessage: "",
  });

  const [agentWaitingTime, setAgentWaitingTime] = useState('');
  const [maxChat, setMaxChat] = useState(0);

  useEffect(() => {
    fetchAgent(selectedOption);
  }, [selectedOption]);

  const fetchAgent = async (selectType) => {
    setIsLoading(true);
    const forAgentData = {
      user_id,
      token,
      method: "retrieve",
      agent_type: selectType,
    };
    try {
      const { data } = await Axios.post(
        `${NODE_HOST_URL}/whatsapp_agent`,
        forAgentData
      );

      if (data.success) {
        const newAgentList = data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setAgentList(newAgentList);
      } else {
        setAgentList([]);
      }
    } catch (error) {
      console.error(error.message);
      // setError({
      //   successMessage: true,
      //   bgColor: "alert-danger",
      //   errorMessage: "Failed to fetch agents.",
      // });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const retrieveHuntStrategy = async () => {
      const dataForSetting = {
        user_id,
        token,
        method: "retrieve_hunt_strategy",
        user_type: "admin",
        brand_number,
        channel: "whatsapp",
      };
      try {
        const { data } = await Axios.post(
          `${NODE_HOST_URL}/whatsapp_setting`,
          dataForSetting
        );
        if (data.success) {
          setSelectedHs(data.data.hunt_strategy);
          setSelectedAgent(data.data.teams ? [data.data.teams] : []);
          setFallbackStartegy(
            data.data.fallback_strategy
          );
          if (data.data.fallback_time > 0) {
            setAgentWaitingTime(data.data.fallback_time)
          }
          setMaxChat(data.data.max_chat)
          setTagStatus(data.data.tag_status === 1 ? true : false);
        }
      } catch (error) {
        console.log(error.message);
        setError({
          successMessage: true,
          bgColor: "alert-danger",
          errorMessage: "Failed to retrieve hunt strategy.",
        });
      }
    };
    retrieveHuntStrategy();
  }, [user_id, token, brand_number]);

  const handleHuntStrategyChange = (event) => {
    setSelectedHs(event.target.value);
  };

  const handleAgent = (e) => {
    setSelectedAgent(e);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      cursor: "pointer",
    }),
  };

  const Option = (props) => (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let agentList;

    if (selectedOption === "agent") {
      agentList = selectedAgent.map((agent) => agent.value);
    }

    const dataForSetting = {
      user_id,
      token,
      method: "hunt_strategy",
      user_type: "admin",
      brand_number,
      agent_type: selectedOption,
      channel: "whatsapp",
      hunt_strategy: selectedHs,
      fallback_time: agentWaitingTime,
      fallback_strategy: fallbackStrategy,
      agent_id: selectedOption === "agent" ? agentList : selectedAgent.value,
      tag_status: tagStatus ? "1" : "0",
      max_chat: maxChat,

    };

    try {
      const { data } = await Axios.post(
        `${NODE_HOST_URL}/whatsapp_setting`,
        dataForSetting
      );
      if (data.success) {
        setError({
          successMessage: true,
          bgColor: "alert-success",
          errorMessage: data.message,
        });
      }
    } catch (error) {
      console.log(error.message);
      setError({
        successMessage: true,
        bgColor: "alert-danger",
        errorMessage: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleTag = (e) => {
    const value = e.target.checked;
    setTagStatus(value ? true : false);
  };

  return (
    <DashboardLayout>
      {isLoading && <Spining />}
      <main className="content">
        {error.successMessage && (
          <Alert type={error.bgColor}>{error.errorMessage}</Alert>
        )}
        <div className="container-fluid p-0">
          <div className="d-flex wFull">
            <div className="ChatLive_card col-md-2">
              <ChatLeftMenu />
            </div>
            <div className="col-md-10">
              <div className="ChatLive_card d-flex flex-column wFull px-3 py-3">
                <div className="TitleBox clearfix">
                  <h3>Settings</h3>
                </div>

                <form onSubmit={handleUpdate}>
                  <div className="row">
                    <div className="d-flex flex-wrap ml-3">
                      <div className="col-12 col-md-auto">
                        <label className="mr-5">Tag Priority</label>
                      </div>
                      <div className="col-12 col-md-auto">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={tagStatus}
                            onChange={handleTag}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>

                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <label>
                        Hunt Strategy
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="mb-2">
                        <select
                          className="form-control"
                          value={selectedHs}
                          onChange={handleHuntStrategyChange}
                          required
                        >
                          <option value="">Select Hunt Strategy</option>
                          <option value="random">Random</option>
                          <option value="sequence">Sequence</option>
                          <option value="parallel">Parallel</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      {agentList.length > 0 && (
                        <>
                          <label>
                            {selectedOption === "agent"
                              ? "Agents"
                              : selectedOption === "team"
                                ? "Select Team"
                                : "Managers"}
                          </label>
                          <div className="mt-1">
                            <Select
                              placeholder={`Select ${selectedOption}`}
                              onChange={handleAgent}
                              options={agentList}
                              isLoading={isLoading}
                              value={selectedAgent}
                              isMulti={selectedOption === "agent"}
                              components={
                                selectedOption === "agent"
                                  ? { Option }
                                  : undefined
                              }
                              styles={customStyles}
                              classNamePrefix="select"
                              hideSelectedOptions={false}
                              required
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {selectedHs === "parallel" && (
                    <div className="row mb-4 ">
                      <div className="col-md-12">
                        <label>
                          Fallback Strategy
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          className="form-control"
                          required
                          onChange={(e) => setFallbackStartegy(e.target.value)}
                          value={fallbackStrategy}
                        >
                          <option value="">Select Strategy</option>
                          <option value="random">Random</option>
                          <option value="sequence">Sequence</option>
                        </select>
                      </div>
                    </div>
                  )}
                  <div className="row mx-auto  mb-4">

                    <label>Agent Reassign Waiting Time (minutes) </label>


                    <div style={{ fontSize: ".8rem" }} className="mb-2">
                      Note<span className="text-danger word-break">*</span> : After this time the chat will be reassigned to another agent
                    </div>

                    <input
                      type="number"
                      className="form-control"
                      placeholder="Set waiting time (minutes)"
                      value={agentWaitingTime}
                      onChange={(e) => setAgentWaitingTime(e.target.value)}
                      min="1"
                      step="1"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="" className="mb-2">Maximum chats</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter maximum number chats that can be assigned to the agent at a time"
                      min="1"
                      step="1"
                      value={maxChat}
                      onChange={(e) => setMaxChat(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-row" style={{ float: "right" }}>
                    <div
                      className="form-group col-md-2"
                      style={{ padding: "0 50px" }}
                    >
                      <button type="submit" className="btn btn-success">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </DashboardLayout>
  );
};

export default Settings;
