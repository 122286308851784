import React, { useEffect, useState } from 'react';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import { Link, useParams } from 'react-router-dom';
import { userloginDetails } from '../Utils/AdminAuth';
import { HOST_URL } from '../Dashboard/api/Api';

const BulkSmsRouteSetting = () => {
    const lStorage = userloginDetails();
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const isAdmin = lStorage.user.isAdmin;
    const { id, userType } = useParams();

    const [email, setEmail] = useState('');
    const [error, setError] = useState({
        errorStatus: false,
        bgColor: '',
        errorMessage: ''
    });
    const [dndChecked, setDndChecked] = useState(false);
    const [showErrorcode, setShowErrorcode] = useState(false);
    const [selectRoute, setSelectRoute] = useState([]);
    const [nationalRoute, setNationalRoute] = useState('');
    const [dndRoute, setDndRoute] = useState('');
    const [internationalRoute, setInternationalRoute] = useState('');
    const [updatedData, setUpdatedData] = useState({});
    const [routeType, setRouteType] = useState(''); // New state for route type

    useEffect(() => {
        getUserDetails();
        getRouteList();
        UpdateData();
    }, []);



    const onNationalRouteChange = (e) => {
        const selectedRouteId = e.target.value;
        setNationalRoute(selectedRouteId);

        console.log(selectedRouteId);

        // Convert selectedRouteId to a number for comparison
        const selectedRoute = selectRoute.find(route => route.id === Number(selectedRouteId));

        console.log(selectedRoute);

        if (selectedRoute) {
            setRouteType(selectedRoute.type);
        } else {
            setRouteType('');
        }
    };



    const getUserDetails = async () => {
        const response = await fetch(`${HOST_URL}/admin_user_list.php?user_id=${userId}&method=retrieve&type=userdetails&ret_user_id=${id}&token=${userToken}`);
        const result = await response.json();
        if (result.success) {
            setEmail(result.data[0].email);
        } else {
            setError({
                errorStatus: true,
                bgColor: 'alert alert-danger alert-dismissible',
                errorMessage: result.message
            });
        }
    };

    const getRouteList = async () => {
        const response = await fetch(`${HOST_URL}/route.php?user_id=${userId}&method=retrieve&token=${userToken}&type=${isAdmin}`);
        const result = await response.json();
        if (result.success) {
            setSelectRoute(result.data);
        }
    };

    const UpdateData = async () => {
        const response = await fetch(`${HOST_URL}/admin_user_list.php?user_id=${userId}&method=ret_bulksms_route_setting&token=${userToken}&type=${isAdmin}&ret_user_id=${id}`);
        const result = await response.json();
        if (result.success) {
            const data = result.data[0];
            console.log(data)
            setUpdatedData(data);
            setNationalRoute(data.routeid || '');
            setDndRoute(data.dnd_alt || '');
            setInternationalRoute(data.intl_route || '');
            setDndChecked(data.dnd_check === 1);
            setShowErrorcode(data.show_errorcode === 1);
            setRouteType(data.type || '')
        }
    };

    const addDetails = async () => {
        const response = await fetch(`${HOST_URL}/admin_user_list.php?user_id=${userId}&method=update_bulksms_route&token=${userToken}&type=${isAdmin}&ret_user_id=${id}&routeid=${nationalRoute}&dnd_alt=${dndRoute}&intl_route=${internationalRoute}&route_type=${routeType}&dnd_check=${dndChecked ? 1 : 0}&show_errorcode=${showErrorcode ? 1 : 0}`);
        const result = await response.json();
        setError({
            errorStatus: true,
            bgColor: result.success ? "alert alert-success" : "alert alert-danger",
            errorMessage: result.message
        });
    };

    return (
        <div>
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3>User Details <strong className='text-info'>{email}</strong></h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">
                                            <nav className="text-center" aria-label="breadcrumb">
                                                <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                                    <Link to={`/admin/user-details/${id}/${userType}`}><li className="breadcrumb-item">User Details</li></Link>
                                                    <Link to={`/admin/user-transaction-details/${id}/${userType}`}><li className="breadcrumb-item">Transaction Details</li></Link>
                                                    <Link to={`/admin/user-price-details/${id}/${userType}`}><li className="breadcrumb-item">Add Price</li></Link>
                                                    <Link to={`/admin/user-add-price/${id}/${userType}`}><li className="breadcrumb-item">Add Credit</li></Link>
                                                    <Link to={`/admin/user-entity-pass/${id}/${userType}`}><li className="breadcrumb-item">Entity By Pass</li></Link>
                                                    <Link to={`/admin/change-currency/${id}/${userType}`}><li className="breadcrumb-item">Change Currency</li></Link>
                                                    <Link to={`/admin/bulk-sms-route/${id}/${userType}`}><li className="breadcrumb-item active">Bulk Sms Route Setting</li></Link>
                                                    <Link to={`/admin/agent-setting/${id}/${userType}`}><li className="breadcrumb-item">Agent Setting</li></Link>
                                                </ol>
                                            </nav>
                                        </h5>
                                    </div>
                                    <div className="card-body">
                                        {error.errorStatus && (
                                            <div className={error.bgColor} role="alert">
                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                                <div>{error.errorMessage}</div>
                                            </div>
                                        )}
                                        <div className="filter-option">
                                            <div className="row mt-3">
                                                <div className='col-12'>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <label>National Route</label>
                                                            <select
                                                                value={nationalRoute}
                                                                onChange={onNationalRouteChange}
                                                                className="form-control"
                                                            >                                                       
                                                                <option value="">Select Route</option>
                                                                {selectRoute.map((options) => (
                                                                    options.channel === "sms" ? (
                                                                        <option value={options.id} key={options.id}>
                                                                            {options.route_name}
                                                                        </option>
                                                                    ) : null
                                                                ))}

                                                            </select>
                                                        </div>
                                                        <div className='col-6'>
                                                            <label>DND Route For Mix</label>
                                                            <select
                                                                value={dndRoute}
                                                                onChange={(e) => setDndRoute(e.target.value)}
                                                                className="form-control"
                                                            >
                                                                <option value="">Select Route</option>
                                                                {selectRoute.map((options) => (
                                                                    options.channel === "sms" ? (
                                                                        <option value={options.id} key={options.id}>
                                                                            {options.route_name}
                                                                        </option>
                                                                    ) : null
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='row mt-3'>
                                                        <div className='col-6'>
                                                            <label>International Route</label>
                                                            <select
                                                                value={internationalRoute}
                                                                onChange={(e) => setInternationalRoute(e.target.value)}
                                                                className="form-control"
                                                            >
                                                                <option value="">Select Route</option>
                                                                {selectRoute.map((options) => (
                                                                    options.channel === "sms" ? (
                                                                        <option value={options.id} key={options.id}>
                                                                            {options.route_name}
                                                                        </option>) : null
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className='col-6'>
                                                            <label>Route Type</label>
                                                            <input
                                                                type="text"
                                                                value={routeType}
                                                                disabled
                                                                placeholder="Route Type"
                                                                className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row mt-3'>
                                                        <div className='col-6'>
                                                            <input
                                                                type="checkbox"
                                                                id="checkbox1"
                                                                checked={dndChecked}
                                                                onChange={() => setDndChecked(!dndChecked)}
                                                            />
                                                            <label className='ml-2'>DND Check</label>
                                                            <input
                                                                type="checkbox"
                                                                id="checkbox2"
                                                                checked={showErrorcode}
                                                                onChange={() => setShowErrorcode(!showErrorcode)}
                                                                className='ml-4'
                                                            />
                                                            <label className='ml-2'>Enable ErrorCode</label>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <button type="button" style={{ float: "right" }} onClick={addDetails} className="btn btn-primary mt-3">Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>
        </div>
    );
};

export default BulkSmsRouteSetting;
