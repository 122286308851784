import React, { useState } from "react";
import Papa from "papaparse";


const UploadCSVFile = (props) => {
  const [csvFile, setcsvFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const fileSizeLimit = 50 * 1024 * 1024;
    
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      console.log("step1");
      
      if (fileExtension !== "csv" ) {
        setErrorMessage("Unsupported file format. Please upload a csv file (.csv).");
        setcsvFile(false);
        return;
      }
      if (file.size > fileSizeLimit) {
        setErrorMessage("File size exceeds 50 MB limit. Please upload a smaller file.");
        setcsvFile(false);
        return;
      }
      setErrorMessage("");
      console.log("step2");
      // props.setLoading(true);
      const reader = new FileReader();

      reader.onload = (e) => {
        const text = e.target.result;
        const rows = text.split("\n").map((row) => row.split(","));
        
        // Optional: Process rows to extract headers and values
        const headers = rows[0]; // Assuming the first row is the header
        const data = rows.slice(1).map((row) => {
          const obj = {};
          headers.forEach((header, index) => {
            obj[header] = row[index];
          });
          return obj;
        });
        console.log("step3");
        sendData(file, data);
        console.log(file);
        
        setcsvFile(true); // Send data to parent component
        // props.setLoading(false);
      };

      reader.readAsText(file);
    }
  };


  const sendData = (data, countData) => {
    props.sendCsvData(data, countData);
    // props.sendcountData(countData)
    console.log(data);
  };


  return (
    <>
      <div className="input-group mb-2 mt-4 mr-sm-2">
        <div className="input-group-prepend">
          <div className="input-group-text">Upload csv file</div>
        </div>
        <input
          type="file"
          name="file"
          accept=".csv"
          onChange={handleFileChange}
          className="form-control"
        />
      </div>

      {errorMessage && (
        <div className="alert alert-danger">{errorMessage}</div>
      )}

      {csvFile && !errorMessage && (
        <div className="alert alert-success">File Upload Successfully</div>
      )}
    </>
  );
};

export default UploadCSVFile;
