import React, { useState, useEffect } from "react";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { NODE_HOST_URL } from "../api/Api";
const RcsOnboarding = () => {
    const [rcsOnboardingList, setRcsOnboardingList] = useState([]);
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;

    useEffect(() => {

        const fetchList = async () => {
            const payload = {
                user_id: userId,
                token: userToken,
                method: "onboarding_retrieve",

            }
            try {
                const response = await axios.post(`${NODE_HOST_URL}/rcs`, payload)
                if (response.data.success) {
                    console.log(response.data.data)
                    setRcsOnboardingList(response.data.data);
                }

            } catch (error) {
                console.log(error)

            }
        }

        fetchList();


    }, [userId])
    return (
        <DashboardLayout>
            <main className="content">
                <div className="container-fluid p-0">
                    <div className="d-flex justify-content-between align-items-center w-100 mb-3 flex-wrap">
                        <div>
                            <h3 className="mb-0">
                                <strong>RCS </strong> Onboarding List
                            </h3>
                        </div>
                        <div className="mt-3 mt-md-0">
                            <Link
                                className="btn btn-primary btn-sm"
                                to="/dashboard/apply-rcs"
                            >
                                Apply Onboarding
                            </Link>
                        </div>
                    </div>





                    <div className="d-flex justify-content-center align-items-center w-100">
                        <div className="card w-100">
                            <div className="card-header">
                                <h5 className="card-title mb-0">Rcs Template List </h5>
                            </div>
                            <hr className="m-0" />
                            <div className="">
                                {rcsOnboardingList ? (
                                    <div className="table-responsive">
                                        <table className="table table-hover  project  my-0">
                                            <thead>
                                                <tr>
                                                    <th>Display Name</th>
                                                    <th>Primary email</th>
                                                    <th>Primary Phone</th>
                                                    <th>Primary website</th>
                                                    <th>Created Date</th>
                                                    <th>Region</th>
                                                    <th>Color</th>
                                                    <th>Manager Name</th>
                                                    <th>Manager Phone</th>
                                                    <th>Manager Email</th>
                                                    <th>Privacy Policy</th>
                                                    <th>Terms of Service</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rcsOnboardingList.map((item) => (
                                                    <tr key={item._id}>
                                                        <td>{item.display_name}</td>
                                                        <td>{item.primary_email}</td>
                                                        <td>{item.primary_phone}</td>
                                                        <td>{item.primary_website}</td>
                                                        <td>{item.created_date}</td>
                                                        <td>{item.region}</td>
                                                        <td>{item.color}</td>
                                                        <td>{item.manager_name}</td>
                                                        <td>{item.manager_phone}</td>
                                                        <td>{item.manager_company_email}</td>
                                                        <td>{item.privacy_policy}</td>
                                                        <td>{item.terms_service}</td>
                                                        <td>
                                                            {item.status === 0 ? (
                                                                <span className="badge badge-primary">
                                                                    {" "}
                                                                    Pending{" "}
                                                                </span>
                                                            ) : item.status === 1 ? (
                                                                <span className="badge bg-success">
                                                                    {" "}
                                                                    Approved{" "}
                                                                </span>
                                                            ) : item.status === 2 ? (
                                                                <span className="badge badge-secondary">
                                                                    {" "}
                                                                    Processing{" "}
                                                                </span>
                                                            ) : item.status === 3 ? (
                                                                <span className="badge badge-info">
                                                                    {" "}
                                                                    Rejected{" "}
                                                                </span>
                                                            ) : null}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            <a
                                                                href={`/dashboard/edit-rcs/${item._id}`}
                                                            >
                                                                <span className="badge bg-info">
                                                                    {" "}
                                                                    Edit{" "}
                                                                </span>{" "}
                                                            </a>{" "}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <div className="text-center">
                                        <div className="card-body">
                                            <p>No Recod Found....</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                </div>
            </main>
        </DashboardLayout>

    )
}

export default RcsOnboarding