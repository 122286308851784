import React, { Component } from "react";
import { Link } from "react-router-dom";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import DashboardLeftMenu from "../../Dashboard/DashboardLeftMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { HOST_URL } from "../api/Api";

class SmsTemplate extends Component {
  constructor(props) {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;

    super();
    this.state = {
      user_id: userId,
      token: userToken,
      template_name: null,
      content: null,
      senderid: "",
      entity_id: "",
      is_unicode: 0,
      is_flash: 0,
      ListofEntityID: "",
      template_id: "",
      varibaleInTemplate: [],
      method: "create",
      errorMessage: "",
      successMessage: false,
      successBg: "alert alert-danger alert-dismissible",
      isValid: null,
    };
    this.getVaribale = this.getVaribale.bind(this);
    this.senderIdHandler = this.senderIdHandler.bind(this);
  }

  remove_linebreaks(str) {
    var regex = /{#[^#]*#}/g;
    str = str.replace(regex, function (match, capture) {
      return match.replace(/[\n\r@#$%^&*{}?,.'":;()/[/]]\s*/g, "");
    });
    return str;
  }

  CreateSMSTemplate() {
    document.querySelector("body").scrollTo(0, 0);
    //console.log('content',this.state.content)
    if (this.state.content === null || this.state.content === "") {
      this.setState({ successMessage: true });
      this.setState({ errorMessage: "Template Body can not be blank" });
      this.setState({ successBg: "alert alert-danger alert-dismissible" });
    } else if (
      this.state.senderid.length < 5 ||
      this.state.senderid.length > 12
    ) {
      this.setState({ successMessage: true });
      this.setState({
        errorMessage: "Sender ID should be greater than 4 and smaller than 12",
      });
      this.setState({ successBg: "alert alert-danger alert-dismissible" });
    } else {
      var newContent = this.remove_linebreaks(this.state.content);
      var bodyObject = {
        user_id: this.state.user_id,
        token: this.state.token,
        template_name: this.state.template_name,
        template_id: this.state.template_id,
        entity_id: this.state.entity_id,
        content: newContent,
        senderid: this.state.senderid,
        is_unicode: this.state.is_unicode,
        is_flash: this.state.is_flash,
        method: "create",
      };
      fetch(`${HOST_URL}/sms_template.php`, {
        method: "post",
        headers: {
          "content-Type": "application/json",
        },
        body: JSON.stringify(bodyObject),
      }).then((result) => {
        result.json().then((resp) => {
          if (resp.success === false) {
            this.setState({ successMessage: true });
            this.setState({ errorMessage: resp.message });
            this.setState({
              successBg: "alert alert-danger alert-dismissible",
            });
          } else {
            this.setState({ successMessage: true });
            this.setState({
              successBg: "alert alert-success alert-dismissible",
            });
            this.props.history.push("sms-template-list");
          }
        });
      });
    }
  }

  componentDidMount() {
    this.ListOfEnlityId();
    this.FatchSMSTemplate();
  }

  getVaribale() {
    var str = this.state.content;
    var regex = /{#([^#]+)#}/g;
    let m;
    var tempArr = [];
    while ((m = regex.exec(str)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      tempArr.push(m[1]);
    }
    this.setState({ varibaleInTemplate: tempArr });
  }

  ListOfEnlityId() {
    fetch(
      `${HOST_URL}/entityid.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        //  console.log(result)
        if (result.success === true) {
          this.setState({ ListofEntityID: result });
        }
      });
    });
  }

  FatchSMSTemplate() {
    fetch(
      `${HOST_URL}/sms_template.php?user_id=${this.state.user_id}&id=${this.props.match.params.id}&method=retrieveid&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        //	console.log(result)
        if (result.success === true) {
          this.setState({
            template_name: result.data[0].template_name,
            template_id: result.data[0].template_id,
            content: result.data[0].content,
            senderid: result.data[0].senderid,
            entity_id: result.data[0].entity_id,
            is_unicode_edit: result.data[0].is_unicode,
            is_flash_edit: result.data[0].is_flash,
          });
        }
      });
    });
  }

  UpdateSMSTemplate() {
    document.querySelector("body").scrollTo(0, 0);
    const senderIdLen=this.state.senderid.length;
    console.log(senderIdLen)

    if (this.state.content === null || this.state.content === "") {
      this.setState({ successMessage: true });
      this.setState({ errorMessage: "Template Body can not be blank" });
      this.setState({ successBg: "alert alert-danger alert-dismissible" });
    } else if (
      senderIdLen < 5 ||
      senderIdLen> 12
    ) {
      this.setState({ successMessage: true });
      this.setState({
        errorMessage: "Sender ID should be greater than 4 and smaller than 12",
      });
      this.setState({ successBg: "alert alert-danger alert-dismissible" });
    } else {

    var newContent = this.remove_linebreaks(this.state.content);
    var bodyObject = {
      user_id: this.state.user_id,
      token: this.state.token,
      id: this.props.match.params.id,
      template_name: this.state.template_name,
      template_id: this.state.template_id,
      content: newContent,
      senderid: this.state.senderid,
      entity_id: this.state.entity_id,
      is_unicode: this.state.is_unicode,
      is_flash: this.state.is_flash,
      method: "update",
    };
    fetch("${HOST_URL}/sms_template.php", {
      method: "post",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify(bodyObject),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.success === false) {
          this.setState({ successMessage: true });
          this.setState({ errorMessage: resp.message });
          this.setState({ successBg: "alert alert-danger alert-dismissible" });
        } else {
          this.setState({ successMessage: true, errorMessage: resp.message });
          this.setState({ successBg: "alert alert-success alert-dismissible" });
        }
      });
    });
  }
  }

  senderIdHandler(e) {
    console.log(e.target.value)
    this.setState({
      senderid: e.target.value,
    });
    // setInputValue(value);
    // Check if the input value is greater than 6 and smaller than 11
    // setIsValid(value > 6 && value < 11);
  }

  render() {
    console.log(this.state.senderid.length)
    return (
      <div className="wrapper">
        <DashboardLeftMenu />
        <div className="main">
          <DashboardHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    <strong>Create New SMS </strong> Template{" "}
                  </h3>
                </div>
                <div className="col-auto ml-auto text-right mt-n1">
                  <Link
                    className="btn btn-primary btn-sm"
                    to="/dashboard/sms-template-list"
                  >
                    Go to SMS Template List{" "}
                  </Link>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <div className="row justify-content-center">
                    <div className="col-md-8">
                      {this.state.successMessage ? (
                        <div className={this.state.successBg} role="alert">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                          {this.state.errorMessage}
                        </div>
                      ) : null}

                      <form id="formSmstemplate">
                        <div className="row justify-content-center">
                          <div className="mb-3 col-md-12">
                            <div className="text-left">
                              <h3 className="font-weight-bold mb-4">
                                {" "}
                                SMS Template{" "}
                              </h3>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <input
                                  value={this.state.template_name}
                                  type="text"
                                  onChange={(event) => {
                                    this.setState({
                                      template_name: event.target.value,
                                    });
                                  }}
                                  className="form-control"
                                  id="inputEmail4"
                                  placeholder="Enter Template Name"
                                />
                              </div>

                              <div className="form-group col-md-6">
                                <input
                                  // defaultValue={this.state.senderid}
                                  type="text"
                                  onChange={this.senderIdHandler}
                                  value={this.state.senderid}
                                  className="form-control"
                                  id="inputEmail4"
                                  placeholder="Enter Sender ID"
                                />
                                {(this.state.senderid.length < 5 ||
                                  this.state.senderid.length > 11) && (
                                  <p style={{ fontSize: "10px", color: "red" }}>
                                    *Sender ID should be greater than 4 and
                                    less than 12
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <input
                                  value={this.state.template_id}
                                  type="text"
                                  onChange={(event) => {
                                    this.setState({
                                      template_id: event.target.value,
                                    });
                                  }}
                                  className="form-control"
                                  id="inputEmail4"
                                  placeholder="Enter DLT Template ID"
                                />
                              </div>

                              <div className="form-group drop-down-icons col-md-6">
                                {this.state.ListofEntityID ? (
                                  <select
                                    value={this.state.entity_id}
                                    onChange={(event) => {
                                      this.setState({
                                        entity_id: event.target.value,
                                      });
                                    }}
                                    className="form-control"
                                  >
                                    <option>Select Entity ID</option>
                                    {this.state.ListofEntityID.data.map(
                                      (item, i) => (
                                        <option key={i} value={item.entity_id}>
                                          {" "}
                                          {item.entity_id}
                                        </option>
                                      )
                                    )}
                                  </select>
                                ) : (
                                  <div id="addTemplateBox">
                                    <label className="form-label">
                                      {" "}
                                      <FontAwesomeIcon
                                        icon={faPlus}
                                        color=""
                                      />{" "}
                                      <Link to="/dashboard/add-entity-id">
                                        {" "}
                                        Add Entity ID{" "}
                                      </Link>{" "}
                                    </label>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group mt-2">
                              <textarea
                                value={this.state.content}
                                onChange={(event) => {
                                  this.setState({
                                    content: event.target.value,
                                  });
                                  this.getVaribale();
                                }}
                                placeholder="Template Message Example : Dear {#Name#}, Thanks for the registraion with us"
                                className="form-control"
                                rows="5"
                              ></textarea>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <span className="ml-4 mt-2">
                                  <input
                                    defaultChecked={
                                      this.state.is_unicode_edit === 1
                                        ? "checked"
                                        : null
                                    }
                                    onChange={(event) => {
                                      this.setState({
                                        is_unicode: event.target.value,
                                      });
                                    }}
                                    value="1"
                                    type="checkbox"
                                    className="form-check-input mr-2"
                                    id="fallback"
                                  />{" "}
                                  <span className="p-2"> is unicode </span>{" "}
                                </span>
                              </div>

                              <div className="col-md-6">
                                <span className="ml-4 mt-2">
                                  <input
                                    defaultChecked={
                                      this.state.is_flash_edit === 1
                                        ? "checked "
                                        : null
                                    }
                                    onChange={(event) => {
                                      this.setState({
                                        is_flash: event.target.value,
                                      });
                                    }}
                                    value="1"
                                    type="checkbox"
                                    className="form-check-input mr-2"
                                    id="fallback"
                                  />{" "}
                                  <span className="p-2"> is flash</span>{" "}
                                </span>
                              </div>
                            </div>

                            {this.props.match.params.id ? (
                              <button
                                type="button"
                                onClick={() => {
                                  this.UpdateSMSTemplate();
                                }}
                                className="btn btn-block mt-3 btn-primary"
                              >
                                Update Template
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={() => {
                                  this.CreateSMSTemplate();
                                }}
                                className="btn btn-block mt-3 btn-primary"
                              >
                                Create Template
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className="col-md-4">
                      <div className="card mt-5">
                        <p className="tooglebox">
                       
                          <p className="text-primary"> Variables In Template</p>
                           
                       
                        </p>

                        <div className="card-body">
                          <form className="form-inline">
                            {this.state.varibaleInTemplate.map(
                              (item, index) => (
                                <div key={index} className="variable mr-1">
                                  <span>{item}</span>
                                </div>
                              )
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <DashboardFooter />
        </div>
      </div>
    );
  }
}

export default SmsTemplate;
