import React, { useEffect, useState } from "react";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import Spining from "../../UI/Spining/Spining";
import Title from "../../UI/Text/Title";

import WtPrev from "./WtPrev";
import WtPrev2 from "./WtPrev2";
import "./WtPrev.css";
import "./WtPrev2.css"
import { NODE_HOST_URL } from "../api/Api";
import Axios from "axios";
import Dialog from "react-dialog";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import WhatsappLogo from "../assets/img/WhatsappLogo-Photoroom.png";
const WhatsappWidget = () => {
  const lStorage = JSON.parse(localStorage.getItem("login"));
  const userId = lStorage.user.userId;
  const userToken = lStorage.token;
  const currentUrl = window.location.hostname;
  const logo = lStorage.user.logo;

  const [selectedTemplate, setSelectedTemplate] = useState('template1');
  const handleTemplateChange = (e) => {
    setSelectedTemplate(e.target.value);
  };
 

  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({
    successMessage: false,
    errorMessage: "",
    bgColor: "",
  });

  const [whatsappno, setWhatsappno] = useState("+918860136424");
  const [displayname, setDisplayname] = useState("Authkey");
  const [logoUrl, setLogoUrl] = useState(
    "https://wpgallery.s3.ap-south-1.amazonaws.com/gallery/202408/66ab6e282f789.png"
  );
  const [wtbgcolor, setWtbgcolor] = useState("#008069");
  const [welcomeText, setWelcomeText] = useState(
    "Hi there 👋, How can I help you?"
  );
  const [preFilledMsg, setPreFilledMsg] = useState("hi");
  const [preFilledTemplate2, setPreFilledTemplate2] = useState("");
  const [widgetStatus, setWidgetStatus] = useState("yes");
  const [position, setPosition] = useState("right");
  const [sideMargin, setSideMargin] = useState("20");
  const [marginBottom, setMarginBottom] = useState("20");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [responsedata, setResponseData] = useState();
  const [copystatus, setCopyStatus] = useState(false);

  useEffect(() => {
    if (currentUrl === "console.authkey.io") {
      setDisplayname("Authkey");
      setLogoUrl(
        "https://wpgallery.s3.ap-south-1.amazonaws.com/gallery/202408/66ab6e282f789.png"
      );
    } else {
      setDisplayname(currentUrl);
      setLogoUrl(WhatsappLogo);
    }
  }, []);

  const handleForm = async (e) => {
    if (whatsappno === "" || displayname === "" || welcomeText === "") {
      setAlert({
        successMessage: true,
        errorMessage: "Please fill all required input",
        bgColor: "alert alert-danger alert-dismissible",
      });
      return;
    }

    let data = {
      user_id: userId,
      method: "create_wig",
      mobile_no: whatsappno,
      welcome_text: welcomeText,
      image_url: logoUrl,
      wig_position: position,
      back_color: wtbgcolor,
      bottom_align: marginBottom,
      side_align: sideMargin,
      default_open: widgetStatus === "yes" ? 1 : 0,
      display_name: displayname,
      pre_filled_message: preFilledMsg,
      pre_filled_Template2: preFilledTemplate2,
      temp_type:selectedTemplate
    };

    setIsLoading(true);
    try {
      const res = await Axios.post(`${NODE_HOST_URL}/whatsapp_widget`, data);
      if (res.data.success === true) {
        setAlert({
          successMessage: true,
          errorMessage: res.data.message,
          bgColor: "alert alert-success alert-dismissible",
        });
        const scriptdata = `<script src="https://console.authkey.io/js/main.js" id="authkey-chat-widget" widget-id="${res.data.data}"></script>`;
        setResponseData(scriptdata);
        setDialogOpen(true);
      } else {
        setAlert({
          successMessage: true,
          errorMessage: res.data.message,
          bgColor: "alert alert-danger alert-dismissible",
        });
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(responsedata);
      setCopyStatus(true);
      setTimeout(() => setCopyStatus(false), 2000);
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };

 
  return (
    <div>
      <DashboardLayout>
        {isLoading && <Spining />}
        <main className="content">
          <div className="container-fluid p-0">
            <Title isButton={false}> WhatsApp Widget </Title>
            {selectedTemplate==="template1"&&<WtPrev
              mobile_no={whatsappno}
              displayname={displayname}
              logourl={logoUrl}
              bgcolor={wtbgcolor}
              wltxt={welcomeText}
              wtstatus={widgetStatus}
              position={position}
              sidemargin={sideMargin}
              marginbottom={marginBottom}
              prefilledmsg= {preFilledMsg}
            />}

             {selectedTemplate==="template2"&&<WtPrev2
              mobile_no={whatsappno}
              displayname={displayname}
              logourl={logoUrl}
              bgcolor={wtbgcolor}
              wltxt={welcomeText}
              wtstatus={widgetStatus}
              position={position}
              sidemargin={sideMargin}
              marginbottom={marginBottom}
              prefilledTemplate2= {setPreFilledTemplate2}
            />}

            {alert.successMessage ? (
              <div className={alert.bgColor} role="alert">
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <div className="">{alert.errorMessage}</div>
              </div>
            ) : null}
            {dialogOpen && (
              <Dialog
                modal={true}
                title="Response Status"
                isDraggable={true}
                onClose={handleCloseDialog}
                maxWidth={"md"}
              >
                <div>
                  <p>
                    Copy and paste this code into desired place of your website
                    (HTML editor, website template, theme, etc.).
                  </p>
                </div>
                <div>
                  <button
                    onClick={handleCopy}
                    className="btn btn-success float-right"
                  >
                    <FontAwesomeIcon icon={faCopy} />{" "}
                    {copystatus ? "Copied" : "copy"}
                  </button>
                </div>
                <div
                  className="w-75"
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#D4EDDA",
                    color: "FFFFFF",
                  }}
                >
                  <p>{responsedata}</p>
                </div>
                {/* <JSONPretty id="json-pretty" data={responsedata}></JSONPretty> */}
              </Dialog>
            )}
            <div className="card">
              <div className="card-body">
                {/* <form > */}
                <div className="form-row align-items-center">
                  <div className="form-group">
                    <label htmlFor="formGroupExampleInput">
                      WhatsApp No.<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="formGroupExampleInput"
                      placeholder="Enter WhatsApp No."
                      value={whatsappno}
                      onChange={(e) => setWhatsappno(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group ml-4">
                    <label htmlFor="formGroupExampleInput">
                      Display Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="formGroupExampleInput"
                      placeholder="Enter Display Name"
                      value={displayname}
                      onChange={(e) => setDisplayname(e.target.value)}
                      required
                    />
                  </div>

                  <div className="form-group ml-4">
                    <label htmlFor="formGroupExampleInput">Logo Url</label>
                    <input
                      type="text"
                      className="form-control"
                      id="formGroupExampleInput"
                      placeholder="Enter logo url"
                      value={logoUrl}
                      onChange={(e) => setLogoUrl(e.target.value)}
                    />
                  </div>

                  <div className="form-group ml-4">
                    <label htmlFor="formGroupExampleInput">
                      Background color
                    </label>
                    <input
                      type="color"
                      className="form-control"
                      id="formGroupExampleInput"
                      placeholder="Enter logo url"
                      value={wtbgcolor}
                      onChange={(e) => setWtbgcolor(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-row">
                 
                <div className="col-sm-4">
                <label htmlFor="formGroupExampleInput">
                      Select Template
                    </label>
                      <div className="input-group drop-down-icons mb-2">
                          <select
                            className="form-control "
                            onChange={handleTemplateChange} 
                            value={selectedTemplate}
                            required
                          >
                          
                            <option value="template1" >Template 1 </option>
                            <option value="template2">Template 2</option>
                            </select>
                            
                      </div>
                     
                    </div>
                  <div className="ml-4">
                    <label>Open widget by default?</label>
                    <div>
                      <input
                        type="radio"
                        id="no"
                        name="wtopen"
                        value="no"
                        className="cursor-pointer form-check-input ml-1"
                        checked={widgetStatus === "no"}
                        onChange={(e) => setWidgetStatus(e.target.value)}
                      />
                       {" "}
                      <label htmlFor="no" className="cursor-pointer ml-4">
                        No
                      </label>
                       {" "}
                      <input
                        type="radio"
                        name="wtopen"
                        id="yes"
                        value="yes"
                        className="ml-4 cursor-pointer form-check-input"
                        checked={widgetStatus === "yes"}
                        onChange={(e) => setWidgetStatus(e.target.value)}
                      />
                       {" "}
                      <label htmlFor="yes" className="cursor-pointer ml-5">
                        Yes
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="exampleFormControlTextarea1">
                      Welcome text<span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Enter welcome text"
                      value={welcomeText}
                      onChange={(e) => setWelcomeText(e.target.value)}
                      maxLength={94}
                    ></textarea>
                  </div>

                  {selectedTemplate==="Template 1"&&<div className="form-group col-md-4">
                    <label htmlFor="exampleFormControlTextarea1">
                      pre-filled message
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Enter pre-filled message"
                      value={preFilledMsg}
                      onChange={(e) => setPreFilledMsg(e.target.value)}
                      maxLength={94}
                    ></textarea>
                  </div>}

                 
                </div>
                <div className="form-row mt-4 justify-content-between">
                  <div>
                    <label>Position</label>
                    <div>
                      <input
                        type="radio"
                        id="left"
                        name="position"
                        value="left"
                        className="cursor-pointer form-check-input ml-1"
                        checked={position === "left"}
                        onChange={(e) => setPosition(e.target.value)}
                      />
                       {" "}
                      <label htmlFor="left" className="cursor-pointer ml-4">
                        Left
                      </label>
                       {" "}
                      <input
                        type="radio"
                        name="position"
                        id="right"
                        value="right"
                        className="ml-4 cursor-pointer form-check-input"
                        checked={position === "right"}
                        onChange={(e) => setPosition(e.target.value)}
                      />
                       {" "}
                      <label htmlFor="right" className="cursor-pointer ml-5">
                        Right
                      </label>
                    </div>
                  </div>
                  <div>
                    <label htmlFor="margin-bottom">
                      Margin Bottom:{" "}
                      <span style={{ color: "#3B7DDD" }}>{marginBottom}px</span>
                    </label>
                    <input
                      type="range"
                      className="form-range"
                      min="0"
                      max="300"
                      id="margin-bottom"
                      value={marginBottom}
                      onChange={(e) => setMarginBottom(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor="margin-right">
                      Margin Right:{" "}
                      <span style={{ color: "#3B7DDD" }}>{sideMargin}px</span>
                    </label>
                    <input
                      type="range"
                      className="form-range"
                      min="0"
                      max="300"
                      id="margin-right"
                      value={sideMargin}
                      onChange={(e) => setSideMargin(e.target.value)}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-center mt-4 ">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleForm}
                  >
                    Create Widget
                  </button>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    </div>
  );
};

export default WhatsappWidget;
